import CustomButton from "@ui/atoms/CustomButton"
import QrCode from "@ui/integrations/QrCode"
import { openUrlInNewWindow } from "@utils/links"
import clsx from "clsx"
import { ReactNode } from "react"

export interface AppInstallerProps {
  url: string
  banner?: ReactNode
  title?: ReactNode
  description?: ReactNode
  className?: string
  ctaLabel?: ReactNode
}

const AppInstaller = ({
  banner,
  description,
  title,
  url,
  className,
  ctaLabel,
}: AppInstallerProps) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center cursor-pointer",
        className
      )}
      onClick={() => openUrlInNewWindow(url)}
    >
      {banner && <div className="mb-4">{banner}</div>}
      {title && <div className="mb-2">{title}</div>}
      {description && <div className="mb-4">{description}</div>}
      <QrCode value={url} fill />
      {ctaLabel && (
        <CustomButton color="primary" className="my-4" fullWidth>
          {ctaLabel}
        </CustomButton>
      )}
    </div>
  )
}

export default AppInstaller
