import {
  APPLE_STORE_APP_URL,
  PLAY_STORE_APP_URL,
} from "@modules/deployments/consts"
import AppInstaller from "@ui/organisms/AppInstaller"
import SplashScreen from "@ui/organisms/SplashScreen"

const MobileAppContainer = () => {
  return (
    <SplashScreen>
      <div className="px-8 flex flex-col items-center justify-center gap-8">
        <AppInstaller
          className="my-4 desk:my-6"
          banner={
            <img
              src="/logos/iPhone-logo.png"
              className="w-[100%] max-w-[250px]"
            />
          }
          url={APPLE_STORE_APP_URL}
          ctaLabel="Installa da App Store"
        />
        <AppInstaller
          className="my-4 desk:my-6"
          banner={
            <img
              src="/logos/android-logo.png"
              className="w-[100%] max-w-[250px]"
            />
          }
          url={PLAY_STORE_APP_URL}
          ctaLabel="Installa da Play Store"
        />
      </div>
    </SplashScreen>
  )
}

export default MobileAppContainer
