import clsx from "clsx"
import QRCode from "react-qr-code"

export interface QrCodeProps {
  size?: number
  value: string
  fill?: boolean
  className?: string
}

const QrCode = ({ size, value, fill, className }: QrCodeProps) => {
  return (
    <QRCode
      value={value}
      size={size}
      className={clsx(
        {
          "h-auto max-w-[100%] w-[100%]": fill,
        },
        className
      )}
      viewBox={`0 0 256 256`}
    />
  )
}

export default QrCode
