import MobileAppContainer from "@container/app/MobileApp"
import { PageSEO } from "@modules/seo"
import { wrapPage } from "@root/page"
import React from "react"

const MobileAppPage = () => {
  return (
    <>
      <MobileAppContainer />
      <PageSEO pageTranslationsKey="app/install" siteTranslationsKey="common" />
    </>
  )
}

export default MobileAppPage

export async function getStaticProps({ locale }: any) {
  return await wrapPage({
    locale,
    translations: ["app/install"],
  })
}
